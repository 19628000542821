import React, { useEffect } from "react";
import { apiUrl, imgDir } from "../../util";
import HomeNews from "../Home/News";
import { useHorizontalScroll } from "../../useHorizontalScroll";

import { useDispatch } from "react-redux";
import { setTransitionStarter } from "../../redux/effectSlice";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

export default function HomePc(props: any) {
    const screenRef = useHorizontalScroll()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const linkTo = (path: string) => {
        dispatch(setTransitionStarter(true))
        setTimeout(()=>{
            navigate(path)
        }, 600)
    }
    const { hash } = useLocation()
    useEffect(()=> {
        if (hash === '#concept') {
            if (screenRef) {
                screenRef.current!.scrollLeft = window.innerWidth - 250
            }
        }
    }, [hash])
    return (
        <div ref={screenRef} className="w-screen h-screen overflow-scroll">
            <div className="flex h-screen w-[calc(200vw+2120px)]">
                <div className="w-screen h-screen shrink-0 flex justify-center items-center" style={{backgroundImage: 'url('+ imgDir() + '/top/fv@2x.jpg)', backgroundSize: 'cover', backgroundPosition: 'center'}}>
                    <div>
                        <svg width="270" height="120" className="text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 145.1 60.85">
                            <path fill="currentColor" d="M129.35,23.07c15.11-6.14,5-28.14-9.95-22-15.01,6.54-5.35,27.82,9.95,22Zm-8.41-1.45c-10.11-4.47-4.88-23.93,6.88-19.07,10.12,4.46,4.88,23.95-6.88,19.07Z"/>
                            <path fill="currentColor" d="M45.67,21.44c-1.44,.06-3.45,.05-4.92,.05-.06-5.95-.14-12.65,.07-18.78,3.42,.03,6.39,.21,9.71,.42V.7c-18.05,.65-12.19-3.36-12.97,14.14,.26,2.77-.64,6.08,0,8.55,3.51-.1,9.47-.11,12.98,0v-2.29c-1.48,.29-3.27,.27-4.86,.34Z"/>
                            <path fill="currentColor" d="M26.05,20.41c-20.43,10.94-16.74-30.51,.99-15.22l.82-3.41C3.53-8.49,1.26,31.12,26.33,22.61c1.45-.22,1.07-2.32,1.18-3.31-.41,.37-.9,.71-1.46,1.12Z"/>
                            <path fill="currentColor" d="M96.7,.73c-4.21,0-8.88-.01-9.57-.03V3.21c.45-.11,5.71-.39,8.04-.34,.16,6.73,.16,14-.16,20.72h3.39c-.33-6.72-.32-13.99-.16-20.72,2.33-.04,7.58,.24,8.04,.34V.7c-.69,.02-5.35,.04-9.57,.04Z"/>
                            <path fill="currentColor" d="M79.1,.68h-2.61c.27,5.32,.26,11.28,.25,16.74-5.1-5.25-10.7-11.41-15.73-16.84h-1.14c.23,7.01,.1,15.63-.57,22.82h2.79c-.25-5.34-.37-11.84-.21-17.01,5.23,5.58,10.88,11.51,15.79,17.2h1c-.02-7.36-.35-15.02,.42-22.91Z"/>
                            <path fill="currentColor" d="M60.97,58.64c-1.44,.06-3.45,.05-4.92,.05-.06-5.95-.14-12.65,.07-18.78,3.42,.03,6.39,.21,9.71,.42v-2.42c-18.05,.65-12.19-3.36-12.98,14.14,.26,2.77-.64,6.09,0,8.55,3.51-.1,9.47-.1,12.98,0v-2.3c-1.49,.29-3.27,.28-4.86,.35Z"/>
                            <path fill="currentColor" d="M94.39,37.94h-2.61c.29,5.32,.25,11.27,.26,16.74-5.1-5.25-10.7-11.41-15.73-16.84h-1.14c.23,7.01,.1,15.63-.57,22.81h2.79c-.25-5.34-.36-11.85-.2-17.01,5.23,5.58,10.88,11.52,15.79,17.2h1c-.03-7.36-.34-15.02,.41-22.91Z"/>
                            <path fill="currentColor" d="M9.57,37.93c-4.21,0-8.88-.01-9.57-.03v2.51c.45-.11,5.71-.39,8.04-.34,.16,6.73,.16,14-.16,20.72h3.39c-.33-6.71-.32-13.99-.16-20.72,2.33-.04,7.58,.24,8.04,.34v-2.51c-.69,.02-5.35,.03-9.57,.03Z"/>
                            <path fill="currentColor" d="M111.39,37.93c-4.21,0-8.88-.01-9.57-.03v2.51c.45-.11,5.71-.39,8.04-.34,.15,6.73,.17,14-.16,20.72h3.39c-.33-6.71-.32-13.99-.16-20.72,2.33-.04,7.58,.24,8.04,.34v-2.51c-.69,.02-5.35,.03-9.57,.03Z"/>
                            <path fill="currentColor" d="M44.24,60.78c-.3-.3-7.99-11.08-8.22-11.36,4.7-.73,7.95-7.25,3.49-10.36-3.78-2.17-8.39-.51-12.52-1.15,.44,6.64,.37,15.95,.04,22.88h3.64c-.32-6.8-.37-14.24-.06-21.07,8.25-2.33,11.13,7.82,2.28,8.53,0,0,6.64,12.1,6.95,12.54h4.41s-.01,0-.02-.01Z"/>
                            <path fill="currentColor" d="M144.04,58.52c-2.86-6.58-5.84-13.92-8.54-20.63h-1.28c-3.29,7.74-6.53,15.26-9.98,22.88h2.89c.72-3.04,6.24-16.03,6.97-17.95,1.11,2.89,6.26,15.25,7.06,17.95h3.92c-.28-.52-.63-1.27-1.05-2.26Z"/>
                        </svg>
                        <h1 className="mt-8 text-white font-bold text-center tracking-wider">チェント・トレンタ</h1>
                    </div>
                </div>
                <div className="bg-black text-white flex items-center h-screen px-32">
                    <div>
                        <h2 className="text-4xl">「粋る」「生きる」「活きる」</h2>
                        <p className="mt-10 leading-8">
                            我々は「Gentleman’s Agreement（ジェントルマンズ・アグリーメント／紳士協定）」<br />
                            の名のもとに集まった“イキる”集団です。ここに全てが集約されています。<br />
                            <br />
                            近い将来セレクトショップという形態が淘汰され、新しい“形”が生まれてくると私自身は考えております。<br />
                            そこで私共が目指すのは、お客さまの「ライフスタイル」に関わり、<br />
                            ライフスタイルをコーディネイトする新しい形となる<br />
                            ネクストセレクトショップを創りだしていきたいと考えております。<br />
                            <br />
                            “者（ヒト）”が“物（商品）”を売り“モノ（価値）”となる<br />
                            社会に貢献できる価値に転換できるのは、社会を形成し社会に責任を持つ“人“でしかなしえません。<br />
                            そう、どのような業界であれ、人で成り立っているわけですから。<br />
                            仕事とは本来ワクワクするもの。そんなワクワクを一緒に感じながら共に“イキり”ましょう。
                        </p>
                    </div>
                </div>
                <HomeNews />
                <div className="w-screen h-screen shrink-0 grid grid-cols-3">
                    <button onClick={()=>linkTo("/cento-trenta")} className="h-screen flex justify-center items-center group relative">
                        <img className="absolute top-0 left-0 w-full h-full object-cover" src={imgDir() + '/top/chapter1bw.jpg'}/>
                        <img className="absolute top-0 left-0 w-full h-full object-cover opacity-0 transition-opacity group-hover:opacity-100 duration-500" src={imgDir() + '/top/chapter1.jpg'}/>
                        <div className="relative">
                            <h5 className="text-white flex justify-center items-center"><span className="inknut text-5xl tracking-wide">Chapter</span><span className="ruqaa text-8xl mb-5 ml-2">1</span></h5>
                            <h4 className="text-white text-center mt-4 font-bold text-xl">はじまり<br />と<br />CENTO TRENTA</h4>
                        </div>
                    </button>

                    <button onClick={()=>linkTo("/d-te")} className="h-screen flex justify-center items-center relative group">
                        <img className="absolute top-0 left-0 w-full h-full object-cover" src={imgDir() + '/top/chapter2bw.jpg'}/>
                        <img className="absolute top-0 left-0 w-full h-full object-cover opacity-0 transition-opacity group-hover:opacity-100 duration-500" src={imgDir() + '/top/chapter2.jpg'}/>
                        <div className="relative">
                            <h5 className="text-white flex justify-center items-center"><span className="inknut text-5xl tracking-wide">Chapter</span><span className="ruqaa text-8xl mb-5 ml-2">2</span></h5>
                            <h4 className="text-white text-center mt-4 font-bold text-xl">新たなステージ<br />と<br />D-TE SHOE STORE</h4>
                        </div>
                    </button>
                    <div className="h-screen flex justify-center items-center group relative">
                        <img className="absolute top-0 left-0 w-full h-full object-cover" src={imgDir() + '/top/chapter3bw.jpg'}/>
                        <div className="relative">
                            <h5 className="text-white flex justify-center items-center"><span className="inknut text-5xl tracking-wide">Chapter</span><span className="ruqaa text-8xl mb-5 ml-2">3</span></h5>
                            <h4 className="text-white text-center mt-4 font-bold text-xl">チェント・トレンタ<br />と<br />オーダースーツ</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}