import axios from "axios";
import React, { useEffect, useState } from "react";
import { apiUrl } from "../util";
import { useInView } from "react-intersection-observer";
import { useDispatch, useSelector } from "react-redux";
import { setDteStaffs, setCentoStaffs } from "../redux/postSlice";
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { useLocation } from "react-router-dom";

export default function Staff(props: any) {
    const dispatch = useDispatch()
    const location = useLocation()
    const staffs = useSelector((state: any) => {
        if (location.pathname === '/d-te') return state.posts.dteStaffs
        else return state.posts.centoStaffs
    })
    
    const { ref, inView } = useInView({
        threshold: 0
    })
    const [swiper, setSwiper] = useState<any>(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (inView && !staffs.length)
        setLoading(true)
        axios.get(apiUrl() + '/wp-json/wp/v2/staff?_embed').then(res => {
            setLoading(false)
            // D-TEとそれ以外で仕分け
            const dteStaffs = []
            const centoStaffs = []
            for(const staff of res.data) {
                if (staff.acf.staff_shop === 'D-TE') {
                    dteStaffs.push(staff)
                } else {
                    centoStaffs.push(staff)
                }
            }
            dispatch(setDteStaffs(dteStaffs))
            dispatch(setCentoStaffs(centoStaffs))
        })
    }, [inView])

    return (
        <div ref={ref} className="py-20 bg-gray-100">
            <div className="lg:w-[880px] mx-auto px-4 lg:px-0">
                <h3 className="text-5xl cormorant tracking-wider">STAFF</h3>
            </div>
            <div className="mt-10 h-[376px]">
                {loading && (
                    <div className="flex justify-center items-center h-[376px]">
                        <div className="animate-spin h-10 w-10 border-4 border-black rounded-full border-t-transparent"></div>
                    </div>
                )}

                {staffs.length && (
                    <Swiper
                        spaceBetween={20}
                        slidesPerView={window.innerWidth<576? 2.4: window.innerWidth<768? 3: window.innerWidth<1024? 4: window.innerWidth<1280? 5: window.innerWidth<1536? 6: 7}
                        onSlideChange={() => console.log('slide change')}
                        className="lg:!overflow-visible"
                        initialSlide={(()=>{
                            if (staffs.length===1) return 0
                            else if (staffs.length===2) return 1
                            else if (staffs.length===3) {
                                if (window.innerWidth<576) return 1
                                else if (window.innerWidth<768) return 1
                                else return 2
                            }
                            else if (staffs.length === 4) {
                                if (window.innerWidth<576) return 1
                                else if (window.innerWidth<768) return 2
                                else return 2
                            } else if (staffs.length === 5) {
                                if (window.innerWidth<576) return 1
                                else if (window.innerWidth<768) return 2
                                else return 3
                            } else {
                                if (window.innerWidth<576) return 1
                                else if (window.innerWidth<768) return 3
                                else return 3
                            }
                        })()}
                        centeredSlides={true}
                        onSwiper={(swiper) => setSwiper(swiper)}
                    >
                        {staffs.map((staff: any, i: number) =>(
                            <SwiperSlide key={'staff-'+staff.id}>
                                <div className="h-60">
                                    {staff._embedded && staff._embedded['wp:featuredmedia'] && (
                                        <img src={staff._embedded['wp:featuredmedia'][0].media_details.sizes.large? staff._embedded['wp:featuredmedia'][0].media_details.sizes.large.source_url: staff._embedded['wp:featuredmedia'][0].media_details.sizes.medium.source_url} className="w-full h-full object-contain"/>
                                    )}
                                </div>
                                <p className="text-lg font-bold text-center mt-4">{staff.title.rendered}</p>
                                <div className="text-center mt-4">
                                    <span className="inline-block border border-black py-1 px-4 text-xs">{staff.acf.staff_shop}</span>
                                </div>
                                {staff.acf.staff_instagram && (
                                    <div className="text-center mt-2">
                                        <a href={staff.acf.staff_instagram} target="_blank" className="inline-block p-2 rounded hover:bg-gray-200">
                                            <svg className="w-5 h-5 " viewBox="0 0 448 512">
                                                {/* <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --> */}
                                                <path fill="currentColor" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/>
                                            </svg>
                                        </a>
                                    </div>
                                )}

                            </SwiperSlide>
                        ))}
                    </Swiper>
                )}
                
            </div>
        </div>
    )
}