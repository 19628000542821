import React, { useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { imgDir } from "../../util";

const slideItems = [
    {
        title: 'ライフスタイルを豊かに',
        description: '日常の中でちょっと気分を上げたい時や、新たなライフステージに向けて心機一転したい時にも。衣服としての機能以上に、あなたのライフスタイルをより豊かにしてくれます。'
    },
    {
        title: '体にフィットする着心地の良さ',
        description: 'サイズの採寸にはじまり、生地選びや試着、完成後の微調整まで一貫してできるからこそ、見た目も美しく、体にフィットする着心地が良いスーツを仕上げることができます。'
    },
    {
        title: '自分好みのカスタマイズ',
        description: '装うことの愉しさは、自分自身を表現できることにもあります。生地やスーツの形、パーツを自分好みにカスタマイズすることで、唯一無二のスーツを仕上げることができます。'
    }
]
export default function ThreeCharmsOfOrderSuit(props: any) {
    const [swiper, setSwiper] = useState<any>(null)

    const slideTo = (index: number) => {
        swiper.slideToLoop(index)
    }
    const slidePrev = () => {
        swiper.slidePrev()
    }
    const slideNext = () => {
        swiper.slideNext()        
    }

    const scrollTo = (id: string) => {
        const el = document.getElementById(id)
        if (el) {
            el.scrollIntoView({ behavior: 'smooth' })
        }
    }

    return (
        <div className="bg-[#EDEDED]  pb-20 relative">
            <h3 className="text-white playfair text-[193px] text-center -tracking-wider">
                HOW TO DRESS
            </h3>
            <div className=" max-w-[1080px] mx-auto">
                <div className="grid grid-cols-3 gap-8">
                    <button type="button" onClick={()=>scrollTo('01')} className="relative bg-white">
                        <img src={imgDir()+'/suit/how-to-dress-01.png'} alt="01-スーツの魅力" className="w-full h-[420px] object-contain object-bottom" />
                        <div className="absolute -top-20 left-[calc(50%-5rem)] w-40 h-40 rounded-full bg-[#C99339] border border-white pointer-events-none flex justify-center items-center flex-col text-white">
                            <h6 className="playfair text-6xl -mt-4">01</h6>
                            <p className="font-bold text-center text-[20px] tracking-widest mt-2">スーツの<br />魅力</p>
                        </div>
                        <div className="text-xs bg-[#C99339] py-2 tracking-wider text-center text-white border-t border-t-white">詳細を見る</div>
                    </button>
                    <button type="button" onClick={()=>scrollTo('02')} className="relative bg-white">
                        <img src={imgDir()+'/suit/how-to-dress-02.png'} alt="02-スーツのマナー" className="w-full h-[420px] object-contain object-bottom" />
                        <div className="absolute -top-20 left-[calc(50%-5rem)] w-40 h-40 rounded-full bg-[#C99339] border border-white pointer-events-none flex justify-center items-center flex-col text-white">
                            <h6 className="playfair text-6xl -mt-4">02</h6>
                            <p className="font-bold text-center text-[20px] tracking-widest mt-2">スーツの<br />マナー</p>
                        </div>
                        <div className="text-xs bg-[#C99339] py-2 tracking-wider text-center text-white border-t border-t-white">詳細を見る</div>
                    </button>

                    <button type="button" onClick={()=>scrollTo('03')} className="relative bg-white">
                        <img src={imgDir()+'/suit/how-to-dress-03.png'} alt="03-スーツの製作工程" className="w-full h-[420px] object-contain object-bottom" />
                        <div className="absolute -top-20 left-[calc(50%-5rem)] w-40 h-40 rounded-full bg-[#C99339] border border-white pointer-events-none flex justify-center items-center flex-col text-white">
                            <h6 className="playfair text-6xl -mt-4">03</h6>
                            <p className="font-bold text-center text-[20px] tracking-widest mt-2">スーツの<br />製作工程</p>
                        </div>
                        <div className="text-xs bg-[#C99339] py-2 tracking-wider text-center text-white border-t border-t-white">詳細を見る</div>
                    </button>
                </div>

            </div>
            <div className="h-32"/>
            <div className="relative pt-20" id="01">
                <div className="absolute top-0 left-[calc(50%-580px)] w-40 h-40 rounded-full bg-[#C99339] border border-white pointer-events-none flex justify-center items-center flex-col text-white">
                    <h6 className="playfair text-6xl -mt-4">01</h6>
                    <p className="font-bold text-center text-[20px] tracking-widest mt-2">スーツの<br />魅力</p>
                </div>
                 <Swiper
                    spaceBetween={20}
                    loop={true}
                    // slidesPerView={1}
                    // loopedSlides={1}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => setSwiper(swiper)}
                >
                    {[0,1,2].map((index: number) => (
                        <SwiperSlide key={index} className="">
                            <div className="relative h-[411px] w-[660px] mx-auto">
                                <div className="grid grid-cols-5">
                                    <div className="col-span-3">
                                        <ul className="flex items-end space-x-6">
                                            {[0,1,2].map((index2: number) => (
                                                <li key={index + '-' + index2}>
                                                    <button onClick={()=>slideTo(index2)} className={`playfair ${index===index2? 'text-[150px] text-[#C99339] leading-[130px]': 'text-5xl text-[rgba(201,147,57,0.5)]'}`}>0{index2+1}</button>
                                                </li>
                                            ))}
                                        </ul>
                                        <h5 className="text-xl mt-8"><span className="inline-block text-white py-1 px-4 bg-[#C99339] font-bold">{slideItems[index].title}</span></h5>
                                        <p className="mt-4 text-[#C99339] text-sm leading-8">{slideItems[index].description}</p>
                                    </div>
                                    <div className="col-span-2 bg-[#EDEDED] ">
                                        <img src={imgDir()+'/suit/three-charms/0'+(index+1)+'.png'} alt="" className="object-contain mix-blend-multiply" />
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <button onClick={()=>slidePrev()} className="absolute top-52 left-[calc(50vw-450px)] block p-2 z-10">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        className="block mx-auto"
                    >
                        <polyline fill="#C99339" stroke="none" points="19,0 19,20 1,10" />
                    </svg>
                </button>
                <button onClick={()=>slideNext()} className="absolute top-52 right-[calc(50vw-450px)] block p-2 z-10">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        className="block mx-auto"
                    >
                        <polyline fill="#C99339" stroke="none" points="1,0 1,20 19,10" />
                    </svg>
                </button>
            </div>
        </div>
    )
}