import React, { useEffect, useState } from "react";
import axios from 'axios'
import { apiUrl } from "../util";

export default function BrandList(props: any) {
    const [brands, setBrands] = useState([])
    useEffect(()=>{
        axios.get( apiUrl() + '/wp-json/wp/v2/brand?per_page=100').then(res => {
            setBrands(res.data)
        })
    }, [])
    return (
        <div className="pt-40 pb-40">
            <div className="lg:w-[880px] mx-auto px-4 lg:px-0">
                <h1 className="cormorant text-center text-[100px] tracking-wider">BRAND LIST</h1>
                <div className="grid grid-cols-4 gap-8 mt-10">
                    {brands.map((brand: any, i: number)=>(
                        <div key={i}>
                            <div className="w-full h-48 border border-black p-4">
                                <img src={brand.acf.brand_logo.sizes.large} className="w-full h-full block object-contain"/>
                            </div>
                            <h6 className="text-center font-bold mt-3">{brand.name}</h6>
                        </div>
                    ))}

                </div>
            </div>
        </div>

    )
}