import React from "react";
import { Link } from "react-router-dom";
import Shop from "../Components/Shop";
import Staff from "../Components/Staff";
import OfficialBlog from "../Components/OfficialBlog";
import IsaoKatoBlog from "../Components/IsaoKatoBlog";
import { apiUrl, imgDir } from "../util";

import { useDispatch } from "react-redux";
import { setTransitionStarter } from "../redux/effectSlice";
import { useNavigate } from "react-router-dom";


export default function CentoTrenta(props: any) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const linkTo = (path: string) => {
        dispatch(setTransitionStarter(true))
        setTimeout(()=>{
            navigate(path)
        }, 600)
    }

    return (
        <>
            <div className="fixed -z-10 inset-0 hidden lg:flex items-center">
                <svg className="w-screen h-auto text-gray-50" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 145.1 60.85">
                    <path fill="currentColor" d="M129.35,23.07c15.11-6.14,5-28.14-9.95-22-15.01,6.54-5.35,27.82,9.95,22Zm-8.41-1.45c-10.11-4.47-4.88-23.93,6.88-19.07,10.12,4.46,4.88,23.95-6.88,19.07Z"/>
                    <path fill="currentColor" d="M45.67,21.44c-1.44,.06-3.45,.05-4.92,.05-.06-5.95-.14-12.65,.07-18.78,3.42,.03,6.39,.21,9.71,.42V.7c-18.05,.65-12.19-3.36-12.97,14.14,.26,2.77-.64,6.08,0,8.55,3.51-.1,9.47-.11,12.98,0v-2.29c-1.48,.29-3.27,.27-4.86,.34Z"/>
                    <path fill="currentColor" d="M26.05,20.41c-20.43,10.94-16.74-30.51,.99-15.22l.82-3.41C3.53-8.49,1.26,31.12,26.33,22.61c1.45-.22,1.07-2.32,1.18-3.31-.41,.37-.9,.71-1.46,1.12Z"/>
                    <path fill="currentColor" d="M96.7,.73c-4.21,0-8.88-.01-9.57-.03V3.21c.45-.11,5.71-.39,8.04-.34,.16,6.73,.16,14-.16,20.72h3.39c-.33-6.72-.32-13.99-.16-20.72,2.33-.04,7.58,.24,8.04,.34V.7c-.69,.02-5.35,.04-9.57,.04Z"/>
                    <path fill="currentColor" d="M79.1,.68h-2.61c.27,5.32,.26,11.28,.25,16.74-5.1-5.25-10.7-11.41-15.73-16.84h-1.14c.23,7.01,.1,15.63-.57,22.82h2.79c-.25-5.34-.37-11.84-.21-17.01,5.23,5.58,10.88,11.51,15.79,17.2h1c-.02-7.36-.35-15.02,.42-22.91Z"/>
                    <path fill="currentColor" d="M60.97,58.64c-1.44,.06-3.45,.05-4.92,.05-.06-5.95-.14-12.65,.07-18.78,3.42,.03,6.39,.21,9.71,.42v-2.42c-18.05,.65-12.19-3.36-12.98,14.14,.26,2.77-.64,6.09,0,8.55,3.51-.1,9.47-.1,12.98,0v-2.3c-1.49,.29-3.27,.28-4.86,.35Z"/>
                    <path fill="currentColor" d="M94.39,37.94h-2.61c.29,5.32,.25,11.27,.26,16.74-5.1-5.25-10.7-11.41-15.73-16.84h-1.14c.23,7.01,.1,15.63-.57,22.81h2.79c-.25-5.34-.36-11.85-.2-17.01,5.23,5.58,10.88,11.52,15.79,17.2h1c-.03-7.36-.34-15.02,.41-22.91Z"/>
                    <path fill="currentColor" d="M9.57,37.93c-4.21,0-8.88-.01-9.57-.03v2.51c.45-.11,5.71-.39,8.04-.34,.16,6.73,.16,14-.16,20.72h3.39c-.33-6.71-.32-13.99-.16-20.72,2.33-.04,7.58,.24,8.04,.34v-2.51c-.69,.02-5.35,.03-9.57,.03Z"/>
                    <path fill="currentColor" d="M111.39,37.93c-4.21,0-8.88-.01-9.57-.03v2.51c.45-.11,5.71-.39,8.04-.34,.15,6.73,.17,14-.16,20.72h3.39c-.33-6.71-.32-13.99-.16-20.72,2.33-.04,7.58,.24,8.04,.34v-2.51c-.69,.02-5.35,.03-9.57,.03Z"/>
                    <path fill="currentColor" d="M44.24,60.78c-.3-.3-7.99-11.08-8.22-11.36,4.7-.73,7.95-7.25,3.49-10.36-3.78-2.17-8.39-.51-12.52-1.15,.44,6.64,.37,15.95,.04,22.88h3.64c-.32-6.8-.37-14.24-.06-21.07,8.25-2.33,11.13,7.82,2.28,8.53,0,0,6.64,12.1,6.95,12.54h4.41s-.01,0-.02-.01Z"/>
                    <path fill="currentColor" d="M144.04,58.52c-2.86-6.58-5.84-13.92-8.54-20.63h-1.28c-3.29,7.74-6.53,15.26-9.98,22.88h2.89c.72-3.04,6.24-16.03,6.97-17.95,1.11,2.89,6.26,15.25,7.06,17.95h3.92c-.28-.52-.63-1.27-1.05-2.26Z"/>
                </svg>
            </div>
            <div className="relative h-screen w-full" style={{backgroundImage: 'url(' + imgDir() + '/cento_trenta/'+(window.innerWidth<576? 'fv-mobile': 'fv@2x')+'.jpg)', backgroundSize: 'cover', backgroundPosition: 'center'}}>
                <div className="hidden md:block absolute top-40 left-0 w-screen">
                    <div className="container max-w-6xl mx-auto">
                        <h2 className="text-white flex items-center"><span className="inknut text-2xl tracking-wide">Chapter</span><span className="ruqaa text-6xl mb-6 ml-1">1</span></h2>
                        <h1 className="text-white">はじまりと CENTO TRENTA</h1>
                    </div>
                </div>
                <div className="flex justify-center items-center w-screen h-screen">
                    <div>
                        <svg className="w-52 md:w-[300px] h-auto text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 145.1 60.85">
                            <path fill="currentColor" d="M129.35,23.07c15.11-6.14,5-28.14-9.95-22-15.01,6.54-5.35,27.82,9.95,22Zm-8.41-1.45c-10.11-4.47-4.88-23.93,6.88-19.07,10.12,4.46,4.88,23.95-6.88,19.07Z"/>
                            <path fill="currentColor" d="M45.67,21.44c-1.44,.06-3.45,.05-4.92,.05-.06-5.95-.14-12.65,.07-18.78,3.42,.03,6.39,.21,9.71,.42V.7c-18.05,.65-12.19-3.36-12.97,14.14,.26,2.77-.64,6.08,0,8.55,3.51-.1,9.47-.11,12.98,0v-2.29c-1.48,.29-3.27,.27-4.86,.34Z"/>
                            <path fill="currentColor" d="M26.05,20.41c-20.43,10.94-16.74-30.51,.99-15.22l.82-3.41C3.53-8.49,1.26,31.12,26.33,22.61c1.45-.22,1.07-2.32,1.18-3.31-.41,.37-.9,.71-1.46,1.12Z"/>
                            <path fill="currentColor" d="M96.7,.73c-4.21,0-8.88-.01-9.57-.03V3.21c.45-.11,5.71-.39,8.04-.34,.16,6.73,.16,14-.16,20.72h3.39c-.33-6.72-.32-13.99-.16-20.72,2.33-.04,7.58,.24,8.04,.34V.7c-.69,.02-5.35,.04-9.57,.04Z"/>
                            <path fill="currentColor" d="M79.1,.68h-2.61c.27,5.32,.26,11.28,.25,16.74-5.1-5.25-10.7-11.41-15.73-16.84h-1.14c.23,7.01,.1,15.63-.57,22.82h2.79c-.25-5.34-.37-11.84-.21-17.01,5.23,5.58,10.88,11.51,15.79,17.2h1c-.02-7.36-.35-15.02,.42-22.91Z"/>
                            <path fill="currentColor" d="M60.97,58.64c-1.44,.06-3.45,.05-4.92,.05-.06-5.95-.14-12.65,.07-18.78,3.42,.03,6.39,.21,9.71,.42v-2.42c-18.05,.65-12.19-3.36-12.98,14.14,.26,2.77-.64,6.09,0,8.55,3.51-.1,9.47-.1,12.98,0v-2.3c-1.49,.29-3.27,.28-4.86,.35Z"/>
                            <path fill="currentColor" d="M94.39,37.94h-2.61c.29,5.32,.25,11.27,.26,16.74-5.1-5.25-10.7-11.41-15.73-16.84h-1.14c.23,7.01,.1,15.63-.57,22.81h2.79c-.25-5.34-.36-11.85-.2-17.01,5.23,5.58,10.88,11.52,15.79,17.2h1c-.03-7.36-.34-15.02,.41-22.91Z"/>
                            <path fill="currentColor" d="M9.57,37.93c-4.21,0-8.88-.01-9.57-.03v2.51c.45-.11,5.71-.39,8.04-.34,.16,6.73,.16,14-.16,20.72h3.39c-.33-6.71-.32-13.99-.16-20.72,2.33-.04,7.58,.24,8.04,.34v-2.51c-.69,.02-5.35,.03-9.57,.03Z"/>
                            <path fill="currentColor" d="M111.39,37.93c-4.21,0-8.88-.01-9.57-.03v2.51c.45-.11,5.71-.39,8.04-.34,.15,6.73,.17,14-.16,20.72h3.39c-.33-6.71-.32-13.99-.16-20.72,2.33-.04,7.58,.24,8.04,.34v-2.51c-.69,.02-5.35,.03-9.57,.03Z"/>
                            <path fill="currentColor" d="M44.24,60.78c-.3-.3-7.99-11.08-8.22-11.36,4.7-.73,7.95-7.25,3.49-10.36-3.78-2.17-8.39-.51-12.52-1.15,.44,6.64,.37,15.95,.04,22.88h3.64c-.32-6.8-.37-14.24-.06-21.07,8.25-2.33,11.13,7.82,2.28,8.53,0,0,6.64,12.1,6.95,12.54h4.41s-.01,0-.02-.01Z"/>
                            <path fill="currentColor" d="M144.04,58.52c-2.86-6.58-5.84-13.92-8.54-20.63h-1.28c-3.29,7.74-6.53,15.26-9.98,22.88h2.89c.72-3.04,6.24-16.03,6.97-17.95,1.11,2.89,6.26,15.25,7.06,17.95h3.92c-.28-.52-.63-1.27-1.05-2.26Z"/>
                        </svg>
                        <h1 className="mt-8 text-white font-bold text-center tracking-wider text-xs md:text-base">チェント・トレンタ</h1>
                        <div className=" md:hidden ">
                                <h2 className="text-white flex justify-center items-center"><span className="inknut text-2xl tracking-wide">Chapter</span><span className="ruqaa text-6xl mb-6 ml-1">1</span></h2>
                                <h1 className="text-white text-center">はじまり<br />と<br />CENTO TRENTA</h1>
                        </div>

                    </div>
                </div>
                <div className="hidden md:block absolute left-0 bottom-40 w-full">
                    <svg width="100" height="20" className="text-white block mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 20">
                        <polyline fill="none" stroke="currentColor" strokeWidth="2" points="1,1 50,19 99,1" />
                    </svg>
                </div>

            </div>
            <div className="bg-gray-100 py-20">
                <div className="lg:w-[880px] mx-auto">
                    <div className="grid grid-cols-1 md:grid-cols-5 gap-6">

                        <div className="col-span-2 flex justify-center items-center md:order-2">
                            <div>
                                <svg className=" block w-20 lg:w-44 h-auto mx-auto mb-6 lg:mb-14" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96.61 89.18">
                                    <path fill="currentColor" d="M47.27,53.86C17.7,72.96-1.22,33.6,16.67,11.28,25.48,1.94,42.39,2.87,49.88,13.41l2.19-9.06C-4.72-19.35-20.55,61.39,32.3,62.9c6.27-.38,13.76-.96,18.51-5.18,0,0,.33-6.82,.33-6.82-1.09,.98-2.37,1.89-3.88,2.97Z"/>
                                    <path fill="currentColor" d="M71.19,28.45c-11.2,0-23.6-.03-25.42-.08v6.67c1.2-.28,15.17-1.03,21.36-.91,.41,17.89,.44,37.22-.43,55.06h9c-.87-17.84-.85-37.17-.44-55.06,6.2-.11,20.15,.63,21.36,.91v-6.67c-1.82,.06-14.22,.08-25.42,.08Z"/>
                                </svg>

                                <svg className="w-40 h-auto lg:w-full text-black" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 145.1 60.85">
                                    <path fill="currentColor" d="M129.35,23.07c15.11-6.14,5-28.14-9.95-22-15.01,6.54-5.35,27.82,9.95,22Zm-8.41-1.45c-10.11-4.47-4.88-23.93,6.88-19.07,10.12,4.46,4.88,23.95-6.88,19.07Z"/>
                                    <path fill="currentColor" d="M45.67,21.44c-1.44,.06-3.45,.05-4.92,.05-.06-5.95-.14-12.65,.07-18.78,3.42,.03,6.39,.21,9.71,.42V.7c-18.05,.65-12.19-3.36-12.97,14.14,.26,2.77-.64,6.08,0,8.55,3.51-.1,9.47-.11,12.98,0v-2.29c-1.48,.29-3.27,.27-4.86,.34Z"/>
                                    <path fill="currentColor" d="M26.05,20.41c-20.43,10.94-16.74-30.51,.99-15.22l.82-3.41C3.53-8.49,1.26,31.12,26.33,22.61c1.45-.22,1.07-2.32,1.18-3.31-.41,.37-.9,.71-1.46,1.12Z"/>
                                    <path fill="currentColor" d="M96.7,.73c-4.21,0-8.88-.01-9.57-.03V3.21c.45-.11,5.71-.39,8.04-.34,.16,6.73,.16,14-.16,20.72h3.39c-.33-6.72-.32-13.99-.16-20.72,2.33-.04,7.58,.24,8.04,.34V.7c-.69,.02-5.35,.04-9.57,.04Z"/>
                                    <path fill="currentColor" d="M79.1,.68h-2.61c.27,5.32,.26,11.28,.25,16.74-5.1-5.25-10.7-11.41-15.73-16.84h-1.14c.23,7.01,.1,15.63-.57,22.82h2.79c-.25-5.34-.37-11.84-.21-17.01,5.23,5.58,10.88,11.51,15.79,17.2h1c-.02-7.36-.35-15.02,.42-22.91Z"/>
                                    <path fill="currentColor" d="M60.97,58.64c-1.44,.06-3.45,.05-4.92,.05-.06-5.95-.14-12.65,.07-18.78,3.42,.03,6.39,.21,9.71,.42v-2.42c-18.05,.65-12.19-3.36-12.98,14.14,.26,2.77-.64,6.09,0,8.55,3.51-.1,9.47-.1,12.98,0v-2.3c-1.49,.29-3.27,.28-4.86,.35Z"/>
                                    <path fill="currentColor" d="M94.39,37.94h-2.61c.29,5.32,.25,11.27,.26,16.74-5.1-5.25-10.7-11.41-15.73-16.84h-1.14c.23,7.01,.1,15.63-.57,22.81h2.79c-.25-5.34-.36-11.85-.2-17.01,5.23,5.58,10.88,11.52,15.79,17.2h1c-.03-7.36-.34-15.02,.41-22.91Z"/>
                                    <path fill="currentColor" d="M9.57,37.93c-4.21,0-8.88-.01-9.57-.03v2.51c.45-.11,5.71-.39,8.04-.34,.16,6.73,.16,14-.16,20.72h3.39c-.33-6.71-.32-13.99-.16-20.72,2.33-.04,7.58,.24,8.04,.34v-2.51c-.69,.02-5.35,.03-9.57,.03Z"/>
                                    <path fill="currentColor" d="M111.39,37.93c-4.21,0-8.88-.01-9.57-.03v2.51c.45-.11,5.71-.39,8.04-.34,.15,6.73,.17,14-.16,20.72h3.39c-.33-6.71-.32-13.99-.16-20.72,2.33-.04,7.58,.24,8.04,.34v-2.51c-.69,.02-5.35,.03-9.57,.03Z"/>
                                    <path fill="currentColor" d="M44.24,60.78c-.3-.3-7.99-11.08-8.22-11.36,4.7-.73,7.95-7.25,3.49-10.36-3.78-2.17-8.39-.51-12.52-1.15,.44,6.64,.37,15.95,.04,22.88h3.64c-.32-6.8-.37-14.24-.06-21.07,8.25-2.33,11.13,7.82,2.28,8.53,0,0,6.64,12.1,6.95,12.54h4.41s-.01,0-.02-.01Z"/>
                                    <path fill="currentColor" d="M144.04,58.52c-2.86-6.58-5.84-13.92-8.54-20.63h-1.28c-3.29,7.74-6.53,15.26-9.98,22.88h2.89c.72-3.04,6.24-16.03,6.97-17.95,1.11,2.89,6.26,15.25,7.06,17.95h3.92c-.28-.52-.63-1.27-1.05-2.26Z"/>
                                </svg>
                                <h1 className="mt-4 lg:mt-8 text-xs lg:text-base font-bold text-center tracking-wider">チェント・トレンタ</h1>
                            </div>
                        </div>
                        <div className="col-span-3 md:order-1 pl-20 pr-10 lg:pl-0 lg:pr-0 relative">
                            <h6 className="hidden md:block md:text-[10rem] md:-mt-10 playfair">2014</h6>
                            <h6 className="absolute -top-20 -left-16 rotate-90 md:hidden text-[7rem] playfair">2014</h6>
                            <h2 className="font-bold text-xl md:text-3xl md:leading-[3rem]">お客様の物語を共に作る、<br />ライフスタイリスト集団</h2>
                            <p className="mt-6 leading-7 md:leading-8 text-sm text-justify">
                                ファッションには、その人の気分を少しだけ上向きにし、新たなステージへの背中を押す力があると私たちは考えます。お客様一人ひとりの生の声を聴き、お客様の人生であり物語であるライフスタイルを共に作る存在でありたい。そんな想いのもとに３人のメンバーが集い、SHOP「CENTO TRENTA」を2014年に創業いたしました。
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Shop />
            <Staff />
            <OfficialBlog />
            <IsaoKatoBlog />
            
            <div className="bg-gray-100 py-20">
                <div className="lg:w-[880px] mx-auto">
                    <h3 className="text-5xl">BLAND LIST</h3>
                </div>
            </div>
            <div className="bg-white md:py-20">
                <div className="md:w-[700px] mx-auto">
                    <div className="md:grid grid-cols-2 gap-4">
                        <div>
                            <button onClick={()=>linkTo("/d-te")} className="h-[250px] md:h-[528px] flex w-full justify-center items-center relative group" >
                                <img className="md:hidden absolute top-0 left-0 w-full h-full object-cover" src={imgDir() + '/top/chapter2bw-mobile.jpg'}/>

                                <img className="hidden md:block absolute top-0 left-0 w-full h-full object-cover" src={imgDir() + '/top/chapter2bw.jpg'}/>
                                <img className="hidden md:block absolute top-0 left-0 w-full h-full object-cover opacity-0 transition-opacity group-hover:opacity-100 duration-500" src={imgDir() + '/top/chapter2.jpg'}/>
                                <div className="relative">
                                    <h5 className="text-white flex justify-center items-center"><span className="inknut text-2xl md:text-4xl tracking-wide">Chapter</span><span className="ruqaa text-6xl md:text-7xl mb-5 ml-2">2</span></h5>
                                    <h4 className="text-white text-center mt-4 font-bold">新たなステージ<br />と<br />D-TE SHOE STORE</h4>
                                </div>
                            </button>
                        </div>
                        <div>
                            <div className="h-[250px] md:h-[528px] flex justify-center items-center relative" >
                                <img className="md:hidden absolute top-0 left-0 w-full h-full object-cover" src={imgDir() + '/top/chapter3bw-mobile.jpg'}/>
                                <img className="hidden md:block absolute top-0 left-0 w-full h-full object-cover" src={imgDir() + '/top/chapter3bw.jpg'}/>

                                <div className="relative">
                                    <h5 className="text-white flex justify-center items-center"><span className="inknut text-2xl md:text-4xl tracking-wide">Chapter</span><span className="ruqaa text-6xl md:text-7xl mb-5 ml-2">3</span></h5>
                                    <h4 className="text-white text-center mt-4 font-bold">チェント・トレンタ<br />と<br />オーダースーツ</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}