import React, { useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { imgDir } from "../../util";

const slideItems = [
    {
        title: 'ヌード寸を計測する',
        description: 'ヌード寸とは、着用する人自身の体のサイズのこと。バストやウエスト、肩幅や首周りなど、全身の採寸をします。',
        src: imgDir() + '/suit/order-flow/01.png'
    },
    {
        title: 'サンプルスーツを試着する',
        description: '計測したヌード寸を元に、体のサイズに合うサンプルスーツを着用頂きます。着用感をチェックし、スーツのサイズを再度確認します。',
        src: imgDir() + '/suit/order-flow/02.png'
    },
    {
        title: 'スーツの生地と色を選ぶ',
        description: 'バンチブックと呼ばれる見本帳をもとに、お好みの生地を選んでいただきます。生地ごとにカラーのラインナップも異なるため、この段階でスーツの色も決めていただきます。',
        src: imgDir() + '/suit/three-charms/03.png'
    },
    {
        title: 'スーツの形を決める',
        description: '襟の幅やポケットのサイズ、ズボンのタックの有無など、スーツの形を自由に決めていだだけます。カスタマイズの種類もそれぞれご説明させていただきますので、スーツの知識がなくても安心してご相談ください。',
        src: imgDir() + '/suit/order-flow/04.png'
    },
    {
        title: '細かなパーツを選ぶ',
        description: 'スーツの裏生地やボタンなど、細かなパーツを決めていただきます。この段階で、スーツの仕上がりイメージが決定します。',
        src: imgDir() + '/suit/order-flow/03.png'
    },
    {
        title: '完成品を試着する',
        description: '仕上がりイメージを決定してから約1ヶ月後、完成品のサンプルを試着いただきます。着用時に気になる箇所があれば、微調整が可能です。',
        src: imgDir() + '/suit/three-charms/02.png'
    },
    {
        title: '微調整後、オーダースーツが完成',
        description: '微調整を行う場合は、試着から約1週間後に完成品が到着します。ライフスタイルに心地よく寄り添う、あなただけのオーダースーツをお愉しみください。',
        src: imgDir() + '/suit/order-flow/05.png'
    }
]

export default function OrderFlow(props: any) {
    const [swiper, setSwiper] = useState<any>(null)
    const [activeIndex, setActiveIndex] = useState<number>(1)

    const slideTo = (index: number) => {
        swiper.slideToLoop(index)
    }
    const slidePrev = () => {
        swiper.slidePrev()
    }
    const slideNext = () => {
        swiper.slideNext()        
    }
    const isActiveIndex = (index: number) => {
        if (index === 0 && activeIndex === 8) return true
        else if (index === 6 && activeIndex === 0) return true
        else return index+1 === activeIndex
    }
    return (
        <div className="bg-[#EDEDED] relative pt-28 pb-20" id="03">
            <div className="absolute top-20 left-[calc(50%-550px)] w-40 h-40 rounded-full bg-[#C99339] border border-white pointer-events-none flex justify-center items-center flex-col text-white">
                <h6 className="playfair text-6xl -mt-4">03</h6>
                <p className="font-bold text-center text-[20px] tracking-widest mt-2">スーツの<br />製作工程</p>
            </div>
            <ul className="flex justify-center space-x-8">
                {slideItems.map((item:any, index: number) => (
                    <li key={'pager-'+index}>
                        {isActiveIndex(index)? (
                            <div className="relative flex justify-center items-center pb-2 w-14 h-14 rounded-full text-3xl tracking-wider font-bold group text-white bg-[#C99339] playfair">
                                0{index+1}
                            </div>
                        ): (
                            <button onClick={()=>slideTo(index)} className="relative block w-14 h-14 rounded-full py-2 text-3xl tracking-wider font-bold group bg-[#EDEDED] text-white playfair">
                                <div className="absolute top-0 left-0 w-14 h-14 rounded-full border-[1.5px] border-white ">
                                </div>
                                0{index}
                                <div className="absolute top-0 left-0 w-14 h-14 pb-2 rounded-full -translate-x-1 -translate-y-1 flex justify-center items-center text-[#C99339] border-[1.5px] border-white bg-white pointer-events-none transition-transform group-hover:translate-x-0 group-hover:translate-y-0">
                                    0{index+1}
                                </div>
                            </button>
                        )}
                    </li>
                ))}
            </ul>
            <div className="relative mt-8">
                <Swiper
                    spaceBetween={20}
                    loop={true}
                    // onSlideChange={() => console.log('slide change')}
                    onActiveIndexChange={(sw) => setActiveIndex(sw.activeIndex)}
                    onSwiper={(swiper) => setSwiper(swiper)}
                >
                    {slideItems.map((item: any, index: number) => (
                        <SwiperSlide key={index} className="">
                            <div className="relative h-[420px] w-[660px] mx-auto">
                                <div className="absolute top-20 -left-10 w-[calc(100%+5rem)] h-px flex">
                                    <div className="w-20 bg-white h-px"></div>
                                    <div className="w-44  h-px"></div>
                                    <div className="grow bg-white h-px"></div>
                                </div>
                                <div className="grid grid-cols-5 gap-6">
                                    <div className="col-span-2 bg-[#EDEDED] flex justify-end">
                                        <img src={item.src} alt="" className="h-[420px] w-auto object-contain mix-blend-multiply" />
                                    </div>
                                    <div className="col-span-3 relative h-full flex items-center">
                                        <span className="absolute playfair text-[320px] right-0 top-16 text-[rgba(255,255,255,0.4)] leading-[200px]">0{index+1}</span>
                                        <div className="relative">
                                            <h5 className="text-xl mt-8 font-bold">{item.title}</h5>
                                            <p className="mt-4  text-sm leading-8">{item.description}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="absolute bottom-16 -left-10 w-[calc(100%+5rem)] h-px flex">
                                    <div className="w-20 bg-white h-px"></div>
                                    <div className="w-44  h-px"></div>
                                    <div className="grow bg-white h-px"></div>
                                </div>

                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <button onClick={()=>slidePrev()} className="absolute top-52 left-[calc(50vw-450px)] block p-2 z-10">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        className="block mx-auto"
                    >
                        <polyline fill="#fff" stroke="none" points="19,0 19,20 1,10" />
                    </svg>
                </button>
                <button onClick={()=>slideNext()} className="absolute top-52 right-[calc(50vw-450px)] block p-2 z-10">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        className="block mx-auto"
                    >
                        <polyline fill="#fff" stroke="none" points="1,0 1,20 19,10" />
                    </svg>
                </button>
            </div>
        </div>
    )
}