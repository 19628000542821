import React from "react";
import { imgDir } from "../../util";
import { useDispatch } from "react-redux";
import { setTransitionStarter } from "../../redux/effectSlice";
import { useNavigate } from "react-router-dom";

const vassStyle = () => {
    const css = `
        #vassTypo {
            animation: vassAnimation 16s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }
        @keyframes vassAnimation {
            0% {
                left:0px;
            }
            100% {
                left: -1396px;
            }
        }

    `
    return (
        <style>
            {css}
        </style>
    )
}

export default function Vass(props: any) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const linkTo = (path: string) => {
        dispatch(setTransitionStarter(true))
        setTimeout(()=>{
            navigate(path)
        }, 600)
    }
    return (
        <>
            {vassStyle()}
            <section className="section bg-[#F0F0F0] pt-24 lg:pt-40 pb-20 relative">
                <div className="relative mx-auto">
                    <h6 className="text-center font-bold tracking-wider">the brands we deal with</h6>
                    <div className="relative w-screen overflow-hidden">
                        <div id="vassTypo" className="absolute top-6 left-0 flex space-x-10 text-[240px] cormorant text-white" style={{width: 748*2*3 + 'px'}}>
                            <div>
                                VASS SHOES
                            </div>
                            <div>
                                VASS SHOES
                            </div>
                            <div>
                                VASS SHOES
                            </div>
                        </div>
                        <img src={imgDir() + '/vass@2x.png'}  className="relative block mx-auto h-[300px] mt-8 mb-4"/>
                    </div>
                    <h3 className="text-center font-bold tracking-wider text-3xl">Vass Shoes</h3>
                    <div className="max-w-[640px] mx-auto px-4">
                        <p className=" text-sm mt-8 leading-6 zen">
                            靴の名産地であるハンガリーで、伝説的靴職人のラズロ・ヴァーシュ氏が1978年に立ち上げたブランド。確かな職人技から生まれるアイテムの数々は、トラディショナルでありながらも圧倒的な存在感を放ちます。
                        </p>
                        <img className="w-full h-auto mt-8" src={imgDir() + '/d-te/vass_img1@2x.jpg'} />
                        <p className=" text-sm mt-8 leading-6 zen">
                        考え抜かれた木型から生まれる極上の履き心地、そして妥協を許さない高品質なマテリアル。「既成靴の最高峰」と称されるその完成度は、足を通した者にしか解らない、まさに究極の逸品です。
                        </p>
                        <div className="px-16">
                            <img className="w-full h-auto mt-8" src={imgDir() + '/d-te/vass_img2@2x.jpg'} />
                        </div>
                        <p className=" text-sm mt-8 leading-6 zen">
                        デニムなどのカジュアルスタイルや、ミリタリーやワークなどのビンテージスタイルとあわせると、コーデにさりげなく品を添えてくれます。アーバンかつスマートな大人のカジュアルコーデにこそ加えて欲しいブランドです。
                        </p>

                    </div>

                </div>
            </section>
            <section className="bg-white pt-14 pb-40">
                <div className="max-w-[740px] mx-auto px-4 lg:px-0">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-32">
                        <div className="flex flex-col">
                            <img src={imgDir() + '/laszlo@2x.png'}  className="relative block mx-auto h-[300px] mt-8 mb-4"/>
                            <h3 className="text-center font-bold tracking-wider text-2xl">Laszlo Budapest</h3>
                            <p className=" text-sm zen mt-6 leading-6 grow">
                                ヨーロッパ有数の靴生産地であるハンガリーで100年以上の歴史を持つ、隠れた名門。ハンドメイドの靴作りの技術を、職人たちが今も守り続けています。
                            </p>
                            <div className="flex justify-center mt-8">
                                <button onClick={()=>linkTo("/d-te/laszlo-budapest")} className="relative block w-40 py-2 text-sm tracking-wider font-bold group bg-white text-black">
                                    <div className="absolute inset-0 border border-black ">
                                    </div>
                                    MORE
                                    <div className="absolute inset-0 -translate-x-1 -translate-y-1 flex justify-center items-center bg-white border border-black pointer-events-none transition-transform group-hover:translate-x-0 group-hover:translate-y-0">
                                    MORE
                                    </div>
                                </button>
                            </div>

                        </div>
                        <div className="flex flex-col">
                            <img src={imgDir() + '/saint_crispin@2x.png'}  className="relative block mx-auto h-[300px] mt-8 mb-4"/>
                            <h3 className="text-center font-bold tracking-wider text-2xl">Saint Crispin's</h3>
                            <p className=" text-sm zen mt-6 leading-6 grow">
                            シューズデザイナーであるマイケル・ローリック氏によって、1992年にオーストリアで設立されたシューズブランド。
                            </p>
                            <div className="flex justify-center mt-8">
                                <button onClick={()=>linkTo("/d-te/saint-crispins")} className="relative block w-40 py-2 text-sm tracking-wider font-bold group bg-white text-black">
                                    <div className="absolute inset-0 border border-black ">
                                    </div>
                                    MORE
                                    <div className="absolute inset-0 -translate-x-1 -translate-y-1 flex justify-center items-center bg-white border border-black pointer-events-none transition-transform group-hover:translate-x-0 group-hover:translate-y-0">
                                    MORE
                                    </div>
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}