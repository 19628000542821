import React from "react";
import { imgDir } from "../util";
import { useDispatch } from "react-redux";
import { setTransitionStarter } from "../redux/effectSlice";
import { useNavigate } from "react-router-dom";

export default function Footer(props: any) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const linkTo = (path: string) => {
        dispatch(setTransitionStarter(true))
        setTimeout(()=>{
            navigate(path)
        }, 600)
    }
    return (
        <>
            <div className="pointer-events-none fixed -bottom-5 lg:bottom-0 left-0 w-screen h-screen z-30" style={{backgroundImage: 'url('+ imgDir() +'/bg-footer-lg.svg)', backgroundSize: 'cover', backgroundPosition: 'bottom'}}>
            </div>
            <div className="h-5 lg:hidden">
            </div>
            <footer className="fixed z-30 bottom-0 left-0 w-screen bg-black mb-4 hidden lg:block">
                <ul className="container mx-auto flex justify-around items-center space-x-6">
                    <li>
                        <button onClick={()=>linkTo("/#concept")} className="text-white">CONCEPT</button>
                    </li>
                    <li>
                        <button onClick={()=>linkTo("/news")} className="text-white">NEWS</button>
                    </li>
                    <li>
                        <button onClick={()=>linkTo("/cento-trenta")} className="text-white">CENTO TRENTA</button>
                    </li>
                    <li>
                        <button onClick={()=>linkTo("/d-te")} className="text-white">D-TE</button>
                    </li>
                    <li>
                        <button onClick={()=>linkTo("/blogs")} className="text-white">BLOG</button>
                    </li>
                    <li>
                        <span  className="text-[rgba(255,255,255,0.6)]">BLAND LIST</span>
                    </li>
                    <li>
                        <span  className="text-[rgba(255,255,255,0.6)]">FAMILY</span>
                    </li>
                </ul>
            </footer>
        </>
    )
}