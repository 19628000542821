import React, { useState } from "react";
import { cloneDeep } from 'lodash'

const RULES = [
    {
        title: 'スーツにおける上半身の常識',
        ng: 'ツキジワができている',
        ok: '肩幅とバストの差異をチェック'
    },
    {
        title: 'スーツにおける腰周りの常識',
        ng: 'ベントが広がっている',
        ok: 'ウエストとヒップのゆとりを計算'
    },
    {
        title: 'スーツにおける肩周りの常識',
        ng: 'スーツの肩が落ちている',
        ok: 'いかり肩やなで肩など自分の体のクセを確認'
    },
    {
        title: 'スーツにおける腕周りの常識',
        ng: '二の腕の部分に皺が入っている',
        ok: '腕周りと肩幅のバランスをチェック'
    },
    {
        title: 'スーツにおける丈感の常識',
        ng: '着丈が極端に短い',
        ok: 'お尻の半分が隠れるくらいの着丈がベスト'
    },
]

export default function RulesOfOrderSuit(props: any) {
    const [collapsed, setCollapsed] = useState([false,false,false,false,false,])

    const changeCollapsed = (index: number) => {
        const copy = cloneDeep(collapsed)
        copy[index] = !copy[index]
        setCollapsed(copy)
    }

    return (
        <div className="bg-[#C99339] relative pt-20 pb-20" id="02">
            <div className="absolute top-20 left-[calc(50%-580px)] w-40 h-40 rounded-full bg-[#C99339] border border-white pointer-events-none flex justify-center items-center flex-col text-white">
                <h6 className="playfair text-6xl -mt-4">02</h6>
                <p className="font-bold text-center text-[20px] tracking-widest mt-2">スーツの<br />マナー</p>
            </div>
            <div className="max-w-[750px] mx-auto">
                {RULES.map((rule: any, i: number) =>(
                    <div className="mb-8" key={i}>
                        <button onClick={()=>changeCollapsed(i)} className="relative block w-full bg-white text-black border-black border-2 py-3 font-bold text-xl tracking-widest">
                            {( '00' + (i+1) ).slice( -2 )}.{rule.title}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="12"
                                className={`absolute block top-5 right-4 ${collapsed[i]? '': '-scale-y-100'}`}
                            >
                                <polyline fill="#00000" stroke="none" points="0,11 14,11 7,1" />
                            </svg>
                        </button>
                        <div className={`overflow-hidden transition-all ${collapsed[i]? 'max-h-96': 'max-h-0'}`}>
                            <div className="border-black border-x-2 border-b-2 bg-white px-8 grid grid-cols-9 justify-center items-center h-56">
                                <div className=" col-span-4 h-full flex items-center justify-end relative">
                                    <svg
                                        width="78"
                                        height="78"
                                        viewBox="0 0 24 24"
                                        className="absolute block right-14"
                                    >
                                        <line fill="none" stroke="#C9933944" strokeWidth="4" x1={2} y1={2} x2={22} y2={22} />
                                        <line fill="none" stroke="#C9933944" strokeWidth="4" x1={2} y1={22} x2={22} y2={2} />
                                    </svg>
                                    <p className="zen font-medium text-lg relative">NG : {rule.ng}</p>
                                </div>
                                <div className="">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        className="block mx-auto"
                                    >
                                        <polyline fill="#C99339" stroke="none" points="1,0 1,20 19,10" />
                                    </svg>
                                </div>

                                <div className="col-span-4 h-full flex items-center justify-start relative">
                                    <svg
                                        width="78"
                                        height="78"
                                        viewBox="0 0 24 24"
                                        className="absolute block left-20"
                                    >
                                        <circle fill="none" stroke="#C9933944" strokeWidth="4" cx={12} cy={12} r={10} />
                                    </svg>
                                    <p className="zen font-medium text-lg relative">OK : {rule.ok}</p>
                                </div>
                            </div>
                        </div>

                    </div>

                ))}

            </div>
        </div>
    )
}