import React, { useState } from "react";
import VariousFeets from "./VariousFeets";
import { apiUrl, imgDir } from "../../util";
import Staff from "../../Components/Staff";
import OfficialBlog from "../../Components/OfficialBlog";
import IsaoKatoBlog from "../../Components/IsaoKatoBlog";
import ReactFullpage from '@fullpage/react-fullpage';
import DteTabs from "./Tabs";
import { useDispatch } from "react-redux";
import { setTransitionStarter } from "../../redux/effectSlice";
import { useNavigate } from "react-router-dom";
import { VideoTag } from "react-video-tag";


const vassStyle = () => {
    const css = `
        #vassTypo {
            animation: vassAnimation 16s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }
        @keyframes vassAnimation {
            0% {
                left:0px;
            }
            100% {
                left: -1396px;
            }
        }
        #laszioTypo {
            animation: laszioAnimation 20s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }
        @keyframes laszioAnimation {
            0% {
                left:0px;
            }
            100% {
                left: -2175px;
            }
        }
        #saintTypo {
            animation: saintAnimation 20s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }
        @keyframes saintAnimation {
            0% {
                left:0px;
            }
            100% {
                left: -1888px;
            }
        }
    `
    return (
        <style>
            {css}
        </style>
    )
}

export default function DtePc(props: any) {
    const [curtainShowing, setCurtainShowing] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const linkTo = (path: string) => {
        dispatch(setTransitionStarter(true))
        setTimeout(()=>{
            navigate(path)
        }, 600)
    }

    return (
        <>
            {vassStyle()}
            <div className="fixed z-0 top-0 left-0 w-screen h-screen bg-[#efede5]">
                <div className="absolute top-[calc(50vh-340px)] left-[calc(50vw-600px)] h-[560px] w-[800px]">
                    <VideoTag autoPlay muted loop playsInline src={imgDir() + '/d-te/d-te.mp4'}  className=' w-[800px] h-[560px] object-contain' />
                </div>
            </div>

            <img className={`fixed top-12 xl:top-16 2xl:top-20 h-[calc(100vh-6rem)] xl:h-[calc(100vh-9rem)] 2xl:h-[calc(100vh-11rem)] w-auto z-10 transition-all duration-1000 ${curtainShowing? 'right-0 opacity-100': '-right-40 opacity-0'}`} src={imgDir() + '/d-te-curtain.svg'} />
            <ReactFullpage
                licenseKey = {'3MR8I-OSQI9-YHIB7-I3QOJ-LCOSK'}
                scrollingSpeed = {1000}
                scrollOverflow = {true}
                onLeave={(origin, destination, direction) => {
                    // console.log('onLeave', origin, destination, direction)
                    if (destination.index === 0) {
                        setCurtainShowing(false)
                    } else {
                        setCurtainShowing(true)
                    }
                }}
                render={({ state, fullpageApi }) => {
                    // console.log('state', state, fullpageApi)
                    return (
                        <ReactFullpage.Wrapper>
                            
                            <section className="section relative z-20 h-screen w-full flex justify-center items-center" style={{backgroundImage: 'url('+ imgDir() + '/d-te/fv@2x.jpg)', backgroundSize: 'cover', backgroundPosition: 'center'}}>
                                <div className="absolute top-40 left-0 w-screen">
                                    <div className="container max-w-6xl mx-auto">
                                        <h2 className="text-white flex items-center"><span className="inknut text-2xl tracking-wide">Chapter</span><span className="ruqaa text-6xl mb-6 ml-1">2</span></h2>
                                        <h1 className="text-white font-bold">新たなステージとD-TE SHOE STORE</h1>
                                    </div>
                                </div>
                                <img className="w-80 h-auto" src={imgDir() + '/logo-d-te.svg'} />
                                <div className="absolute left-0 bottom-40 w-full">
                                    <svg width="100" height="20" className="text-white block mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 20">
                                        <polyline fill="none" stroke="currentColor" strokeWidth="2" points="1,1 50,19 99,1" />
                                    </svg>
                                </div>
                
                            </section>

                            <section className="section relative">
                                <div className="lg:w-[880px] px-4 lg:px-0 mx-auto relative">
                                    <div className="grid grid-cols-3 min-h-[460px]">
                                        <div className="relative">
                                        </div>
                                        <DteTabs />
                                    </div>

                                </div>
                            </section>

                            <section className="section bg-[#F0F0F0] py-20 relative">
                                <div className="relative mx-auto">
                                    <h6 className="text-center font-bold tracking-wider">the brands we deal with</h6>
                                    <div className="relative w-screen overflow-hidden">
                                        <div id="vassTypo" className="absolute top-6 left-0 flex space-x-10 text-[240px] cormorant text-white" style={{width: 748*2*3 + 'px'}}>
                                            <div>
                                                VASS SHOES
                                            </div>
                                            <div>
                                                VASS SHOES
                                            </div>
                                            <div>
                                                VASS SHOES
                                            </div>
                                        </div>
                                        <img src={imgDir() + '/vass@2x.png'}  className="relative block mx-auto h-[300px] mt-8 mb-4"/>
                                    </div>
                                    <h3 className="text-center font-bold tracking-wider text-3xl">Vass Shoes</h3>
                                    <p className="text-center text-sm mt-8 leading-6 zen">
                                    伝説的靴職人のラズロ・ヴァーシュ氏が1978年にハンガリーで立ち上げたブランド。<br />トラディショナルでありながらも圧倒的な存在感を放ちます。
                                    </p>
                                    <div className="flex justify-center mt-8">
                                        <button onClick={()=>linkTo("/d-te/vass-shoes")} className="relative block w-40 py-2 text-sm tracking-wider font-bold group bg-white text-black">
                                            <div className="absolute inset-0 border border-black ">
                                            </div>
                                            MORE
                                            <div className="absolute inset-0 -translate-x-1 -translate-y-1 flex justify-center items-center bg-white border border-black pointer-events-none transition-transform group-hover:translate-x-0 group-hover:translate-y-0">
                                            MORE
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </section>

                            <section className="section bg-[#F0F0F0] py-20 relative">
                                <div className="relative">
                                    <h6 className="text-center font-bold tracking-wider">the brands we deal with</h6>
                                    <div className="relative w-screen overflow-hidden">
                                        <div id="laszioTypo" className="absolute top-6 left-0 flex space-x-10 text-[240px] cormorant text-white" style={{width: 1248*2*3 + 'px'}}>
                                            <div className="uppercase">
                                                Laszlo Budapest
                                            </div>
                                            <div className="uppercase">
                                                Laszlo Budapest
                                            </div>
                                            <div className="uppercase">
                                                Laszlo Budapest
                                            </div>
                                        </div>
                                        <img src={imgDir() + '/laszlo@2x.png'}  className="relative block mx-auto h-[300px] mt-8 mb-4"/>
                                    </div>
                                    <h3 className="text-center font-bold tracking-wider text-3xl">Laszlo Budapest</h3>
                                    <p className="text-center text-sm zen mt-8 leading-6">
                                    ヨーロッパ有数の靴生産地であるハンガリーで100年以上の歴史を持つ、隠れた名門。<br />ハンドメイドの靴作りの技術を、職人たちが今も守り続けています。
                                    </p>
                                    <div className="flex justify-center mt-8">
                                        <button onClick={()=>linkTo("/d-te/laszlo-budapest")} className="relative block w-40 py-2 text-sm tracking-wider font-bold group bg-white text-black">
                                            <div className="absolute inset-0 border border-black ">
                                            </div>
                                            MORE
                                            <div className="absolute inset-0 -translate-x-1 -translate-y-1 flex justify-center items-center bg-white border border-black pointer-events-none transition-transform group-hover:translate-x-0 group-hover:translate-y-0">
                                            MORE
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </section>

                            <section className="section bg-[#F0F0F0] py-20 relative">
                                <div className="relative mx-auto">
                                    <h6 className="text-center font-bold tracking-wider">the brands we deal with</h6>
                                    <div className="relative w-screen overflow-hidden">
                                        <div id="saintTypo" className="absolute top-6 left-0 flex space-x-10 text-[240px] cormorant text-white" style={{width: 1248*2*3 + 'px'}}>
                                            <div className="uppercase">
                                            Saint Crispin's
                                            </div>
                                            <div className="uppercase">
                                            Saint Crispin's
                                            </div>
                                            <div className="uppercase">
                                            Saint Crispin's
                                            </div>
                                        </div>
                                        <img src={imgDir() + '/saint_crispin@2x.png'}  className="relative block mx-auto h-[300px] mt-8 mb-4"/>
                                    </div>
                                    <h3 className="text-center font-bold tracking-wider text-3xl">Saint Crispin's</h3>
                                    <p className="text-center text-sm zen mt-8 leading-6">
                                    シューズデザイナーであるマイケル・ローリック氏によって、1992年にオーストリアで設立されたシューズブランド。
                                    </p>
                                    <div className="flex justify-center mt-8">
                                        <button onClick={()=>linkTo("/d-te/saint-crispins")} className="relative block w-40 py-2 text-sm tracking-wider font-bold group bg-white text-black">
                                            <div className="absolute inset-0 border border-black ">
                                            </div>
                                            MORE
                                            <div className="absolute inset-0 -translate-x-1 -translate-y-1 flex justify-center items-center bg-white border border-black pointer-events-none transition-transform group-hover:translate-x-0 group-hover:translate-y-0">
                                            MORE
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </section>

                            
                            <section className="section">
                                <div className="bg-white py-20">
                                    <h5 className="text-center font-bold">靴を楽しむ足元</h5>
                                    <h4 className="text-center text-[100px] cormorant tracking-wide">VARIOUS FEET</h4>
                                    <VariousFeets />
                                </div>

                                <Staff />
                                <div className="bg-white">
                                    <OfficialBlog />
                                    <IsaoKatoBlog />
                                </div>

                                <div className="bg-white py-20">
                                    <div className="md:w-[700px] mx-auto">
                                        <div className="grid grid-cols-2 gap-4">
                                            <div>
                                                <button onClick={()=>linkTo("/cento-trenta")} className="h-[528px] w-full flex justify-center items-center relative group" >
                                                    <img className="absolute top-0 left-0 w-full h-full object-cover" src={imgDir() + '/top/chapter1bw.jpg'}/>
                                                    <img className="absolute top-0 left-0 w-full h-full object-cover opacity-0 transition-opacity group-hover:opacity-100 duration-500" src={imgDir() + '/top/chapter1.jpg'}/>
                                                    <div className="relative">
                                                        <h5 className="text-white flex justify-center items-center"><span className="inknut text-4xl tracking-wide">Chapter</span><span className="ruqaa text-7xl mb-5 ml-2">1</span></h5>
                                                        <h4 className="text-white text-center mt-4 font-bold">はじまり<br />と<br />CENTO TRENTA</h4>
                                                    </div>
                                                </button>
                                            </div>
                                            <div>
                                                <div className="h-[528px] flex justify-center items-center relative" >
                                                    <img className="absolute top-0 left-0 w-full h-full object-cover" src={imgDir() + '/top/chapter3bw.jpg'}/>

                                                    <div className="relative">
                                                        <h5 className="text-white flex justify-center items-center"><span className="inknut text-4xl tracking-wide">Chapter</span><span className="ruqaa text-7xl mb-5 ml-2">3</span></h5>
                                                        <h4 className="text-white text-center mt-4 font-bold">チェント・トレンタ<br />と<br />オーダースーツ</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="py-20">

                                </div>
                            </section>
                        </ReactFullpage.Wrapper>
                    )
                }}
            />            
        </>
    )
}