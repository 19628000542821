import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setTransitionStarter } from "../redux/effectSlice";
import { useNavigate } from "react-router-dom";

const thamStyle = () => {
    const css = `
        .tham:hover {
            opacity: 1;
        }
        .tham-w-8 .tham-box .tham-inner {
            height: 2px;
        }
      .tham-w-8 .tham-box .tham-inner::before {
        top: -10px;
        border-color: #fff;
        height: 2px;
    }
      .tham-w-8 .tham-box .tham-inner::after {
        bottom: -10px;
        border-color: #fff;
        height: 2px;
    }
    `
    return (
        <style>
            {css}
        </style>
    )
}

export default function MobileMenu(props: any) {
    const [isGlobalMenuOpen, setIsGlobalMenuOpen] = useState(false)
    const location = useLocation()
    const isHome = () => {
        return location.pathname === '/'
    }
    const scrollToContents = () => {
        document.getElementById('concept')?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      };

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const linkTo = (path: string) => {
        dispatch(setTransitionStarter(true))
        setTimeout(()=>{
            navigate(path)
        }, 600)
    }
    return (
        <>
        {thamStyle()}
        <div className="relative z-50">
            <div className="fixed z-20 top-4 right-4 ">
                <div 
                    className={`tham tham-e-squeeze tham-w-8 scale-75 ${isGlobalMenuOpen? 'tham-active':''}`}
                    onClick={()=>setIsGlobalMenuOpen(!isGlobalMenuOpen)}
                >
                <div className="tham-box">
                    <div className="tham-inner bg-white " />
                </div>
                </div>
            </div>
            <div className={`flex justify-center items-center fixed z-10 top-0 left-0 w-screen h-screen bg-[rgba(0,0,0,0.8)] transition-opacity duration-500 ${isGlobalMenuOpen? 'opacity-100': 'pointer-events-none opacity-0'}`}>
                <ul className=" text-center space-y-4">
                    <li className="">
                        {isHome()? (
                            <button className="cormorant text-3xl tracking-wider text-white" onClick={()=>{scrollToContents(); setIsGlobalMenuOpen(false)}}>CONCEPT</button>
                        ): (
                            <button onClick={()=>{linkTo("/#concept"); setIsGlobalMenuOpen(false)}} className="cormorant text-3xl tracking-wider text-white">CONCEPT</button>
                        )}
                    </li>
                    <li className="">
                        <button onClick={()=>{linkTo("/news"); setIsGlobalMenuOpen(false)}} className="cormorant text-3xl tracking-wider text-white" >NEWS</button>
                    </li>
                    <li className="">
                        <button onClick={()=>{linkTo("/cento-trenta"); setIsGlobalMenuOpen(false)}} className="cormorant text-3xl tracking-wider text-white" >CENTO TRENTA</button>
                    </li>
                    <li className="">
                        <button onClick={()=>{linkTo("/d-te"); setIsGlobalMenuOpen(false)}} className="cormorant text-3xl tracking-wider text-white" >D-Te</button>
                    </li>
                    <li className="">
                        <button onClick={()=>{linkTo("/blogs"); setIsGlobalMenuOpen(false)}} className="cormorant text-3xl tracking-wider text-white" >BLOG</button>
                    </li>
                    <li className="">
                        <a href="https://centotrenta.shop-pro.jp/" target="_blank" className="cormorant text-3xl tracking-wider text-white flex justify-center items-center">
                            ONLINE SHOP
                            <svg className="w-6 h-6 ml-2"  viewBox="0 0 24 24">
                                <path fill="currentColor" d="M5,17.59L15.59,7H9V5H19V15H17V8.41L6.41,19L5,17.59Z" />
                            </svg>
    
                        </a>
                    </li>
                    <li className="">
                        <a href="https://centotrenta.jp/recruit/" target="_blank" className="cormorant text-3xl tracking-wider text-white flex justify-center items-center">
                            RECRUIT
                            <svg className="w-6 h-6 ml-2"  viewBox="0 0 24 24">
                                <path fill="currentColor" d="M5,17.59L15.59,7H9V5H19V15H17V8.41L6.41,19L5,17.59Z" />
                            </svg>
    
                        </a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/centotrentaofficial/" target="_blank" className="cormorant text-3xl tracking-wider text-white">
                            <svg className="d-inline-block mx-auto" aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 448 512">
                                <path fill="currentColor" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
                            </svg>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        </>

    )
}