import React from "react";

export default function Family(props: any) {
    return (
        <div className="bg-[#171717] text-white">
            <div className="w-screen h-screen flex justify-center items-center relative">
                <h1 className="cormorant text-[100px] text-center tracking-wider">CENTO TRENTA’S<br />FAMILY</h1>
            </div>
        </div>
    )
}