import { useNavigate } from "react-router-dom";
import { setTransitionStarter } from "../../redux/effectSlice";
import { imgDir } from "../../util";
import { useDispatch } from "react-redux";

export default function OrderSuitCc(props: any) {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const linkTo = (path: string) => {
        dispatch(setTransitionStarter(true))
        setTimeout(()=>{
            navigate(path)
        }, 600)
    }
    return (
        <div className="bg-[#005880] text-white">
            <button onClick={()=>linkTo("/order-suit")} className="fixed top-44 left-0 flex items-center bg-white py-2 pl-4 pr-8 z-10 text-black border-b-2 border-b-black playfair tracking-wider">
                <svg className="w-5 h-5 rotate-180 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>play</title><path d="M8,5.14V19.14L19,12.14L8,5.14Z" /></svg>
                Page Back
            </button>
            <div className="relative pt-40">
                <img src={imgDir() + '/suit/cc/1.jpg'} className="absolute top-0 left-0 w-[calc(50vw+260px)] h-[1185px] object-cover" />
                <img src="https://placehold.jp/480x640.png" className="absolute bottom-0 right-0 w-[calc(50vw+260px)] h-[800px] object-cover" />
                <div className="grid grid-cols-2 relative">
                    <div>
                    </div>
                    <div className="h-[1200px]">
                        <img src={imgDir() + '/suit/logo-cc.svg'} className="w-[532px] h-auto"/>
                        <div className="mt-10 px-6">
                            <h2 className=" text-3xl leading-loose">豊かなカスタム力とそれに反した<br />コストパフォーマンス</h2>
                            <p className=" leading-[30px] text-xs mt-6">
                                チェントトレンタ創業時より依頼している工場にて作るC.C シリーズ。<br />
                                お客様のご希望に限界まで応えられる柔軟なオーダースタイルと、初めてオーダースーツに<br />
                                挑戦される方にも優しいプライスレンジが特徴です。<br />
                                普段のビジネスシーンにも自分にあった一着が欲しい…<br />
                                そんなご希望に沿える、チェントトレンタ定番のオーダーライン。
                            </p>
                        </div>
                    </div>
                </div>
                <div className="max-w-[980px] mx-auto relative">
                    <div className="h-[340px]">
                        <h2 className=" text-3xl leading-loose">バリエーションを広げて<br />充実したスーツライフを。</h2>
                        <p className=" leading-[30px] text-xs mt-6 w-[512px] tracking-wide text-justify">
                        「いろんな色や柄、種類のスーツを気分に合わせて使い分けたい！」<br />
                        そんな洋服好きの皆様には、比較的プライスを抑えて幅広いオーダースタイルにお応えできるC.Cシリーズが最適。シンプルなものから少し派手な色柄、変わったデザインなど、ワガママにお応えできるのが特徴です。
                        </p>
                    </div>
                </div>
            </div>

            <div className="max-w-[1100px] mx-auto mt-28 pb-40">
                <h3 className="text-5xl playfair font-bold tracking-widest">PRICE</h3>
                <div className="bg-white mt-8">
                    <table className="w-full border-spacing-px border-separate text-center">
                        <tbody>
                            <tr>
                                <th colSpan={2} className="bg-[#005880] p-2 text-xl">
                                    2ピース
                                </th>
                                <th className="bg-[#005880] p-2 text-xl">3ピース</th>
                                <th colSpan={2} className="bg-[#005880] p-2 text-xl">
                                    ジャケット
                                </th>
                                <th className="bg-[#005880] p-2 text-xl">パンツ</th>
                            </tr>
                            <tr>
                                <td className="bg-[#005880] p-2 text-[15px]">シングル 3.2m</td>
                                <td className="bg-[#005880] p-2 text-[15px]">ダブル 3.2m</td>
                                <td className="bg-[#005880] p-2 text-[15px]">シングル 3.8m</td>
                                <td className="bg-[#005880] p-2 text-[15px]">シングル 2m</td>
                                <td className="bg-[#005880] p-2 text-[15px]">ダブル 2m</td>
                                <td className="bg-[#005880] p-2 text-[15px]">1.8m</td>
                            </tr>
                            <tr>
                                <td className="bg-[#005880] p-2 text-[15px]">¥70,000~</td>
                                <td className="bg-[#005880] p-2 text-[15px]">¥76,000~</td>
                                <td className="bg-[#005880] p-2 text-[15px]">¥88,000~</td>
                                <td className="bg-[#005880] p-2 text-[15px]">¥47,000~</td>
                                <td className="bg-[#005880] p-2 text-[15px]">¥52,000~</td>
                                <td className="bg-[#005880] p-2 text-[15px]">¥27,000~</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <h5 className="text-2xl mt-20 tracking-wider"><span className="inline-block border-b border-b-white pb-2">CC SERIESについて問い合わせる</span></h5>
                <p className="text-xs tracking-wider mt-10">生地、デザイン、お値段などなんでもお気軽にお問い合わせください。チェントトレンタは皆様のスーツライフを応援いたします。</p>
                <div className="flex mt-10 gap-10">
                    <a href="#" className="relative block w-60 py-2 text-sm tracking-wider font-bold group bg-[#005880] text-white">
                        <div className="absolute inset-0 border border-white ">
                        </div>
                        メールでお問い合わせ
                        <div className="absolute inset-0 -translate-x-1 -translate-y-1 flex justify-center items-center bg-[#005880] border border-white pointer-events-none transition-transform group-hover:translate-x-0 group-hover:translate-y-0">
                        メールでお問い合わせ
                        </div>
                    </a>
                    <a href="#" className="relative block w-60 py-2 text-sm tracking-wider font-bold group bg-[#005880] text-white">
                        <div className="absolute inset-0 border border-white ">
                        </div>
                        お電話でお問い合わせ
                        <div className="absolute inset-0 -translate-x-1 -translate-y-1 flex justify-center items-center bg-[#005880] border border-white pointer-events-none transition-transform group-hover:translate-x-0 group-hover:translate-y-0">
                        お電話でお問い合わせ
                        </div>
                    </a>
                </div>
            </div>
            <div className="h-[680px] bg-black relative">
                <img src={imgDir() + '/suit/cc/banner-cs.jpg'} className="absolute top-0 left-0 w-[calc(100vw-400px)] h-full object-cover" />
                <div className="absolute right-40 top-28">
                    <img src={imgDir() + '/suit/logo-cs.svg'} className="w-[393px] h-auto"/>
                    <h4 className=" text-2xl leading-loose text-right mt-10">日本で屈指の工場が作る<br />最高品質の着心地とデザイン</h4>
                    <button onClick={()=>linkTo("/order-suit/cs")} className="relative block ml-auto mt-10 w-52 py-2 text-sm tracking-wider font-bold group bg-black text-black border border-white">
                        <div className="absolute inset-0 border border-black ">
                        </div>
                        CS SERIESを見る
                        <div className="absolute inset-0 -translate-x-1 -translate-y-1 flex justify-center items-center bg-white pointer-events-none transition-transform group-hover:translate-x-0 group-hover:translate-y-0">
                        CS SERIESを見る
                        </div>
                    </button>
                </div>
            </div>

        </div>

    )
}