import React from "react";
import { imgDir } from "../../util";
import { useDispatch } from "react-redux";
import { setTransitionStarter } from "../../redux/effectSlice";
import { useNavigate } from "react-router-dom";

const saintStyle = () => {
    const css = `
        #saintTypo {
            animation: saintAnimation 20s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }
        @keyframes saintAnimation {
            0% {
                left:0px;
            }
            100% {
                left: -1888px;
            }
        }
    `
    return (
        <style>
            {css}
        </style>
    )
}

export default function SaintCrispins(props: any) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const linkTo = (path: string) => {
        dispatch(setTransitionStarter(true))
        setTimeout(()=>{
            navigate(path)
        }, 600)
    }
    return (
        <>
            {saintStyle()}
            <section className="section bg-[#F0F0F0] pt-24 lg:pt-40 pb-20 relative">
                <div className="relative mx-auto">
                    <h6 className="text-center font-bold tracking-wider">the brands we deal with</h6>
                    <div className="relative w-screen overflow-hidden">
                        <div id="saintTypo" className="absolute top-6 left-0 flex space-x-10 text-[240px] cormorant text-white" style={{width: 1248*2*3 + 'px'}}>
                            <div className="uppercase">
                            Saint Crispin's
                            </div>
                            <div className="uppercase">
                            Saint Crispin's
                            </div>
                            <div className="uppercase">
                            Saint Crispin's
                            </div>

                        </div>
                        <img src={imgDir() + '/saint_crispin@2x.png'}  className="relative block mx-auto h-[300px] mt-8 mb-4"/>
                    </div>
                    <h3 className="text-center font-bold tracking-wider text-3xl">Saint Crispin's</h3>
                    <div className="max-w-[640px] mx-auto px-4">
                        <p className=" text-sm mt-8 leading-6 zen">
                        シューズデザイナーであるマイケル・ローリック氏によって、1992年にオーストリアで設立されたシューズブランド。生産工程のほとんどを手作業に依拠し、たった十数人の熟練工たちが月産80足という丁寧かつ極小のロットで靴づくりを行なっています。
                        </p>
                        <img className="w-full h-auto mt-8" src={imgDir() + '/d-te/saint_crispins_img1@2x.jpg'} />
                        <p className=" text-sm mt-8 leading-6 zen">
                        流麗な見た目からは想像もつかないような快適な履き心地は、職人たちの技術のなせる技。「足を入れられる美術品」と呼んで然るべき、宝石のような靴たちです。その優れたデザイン性からは、美しさの中にどこか野蛮さを感じます。
                        </p>
                        <div className="px-16">
                            <img className="w-full h-auto mt-8" src={imgDir() + '/d-te/saint_crispins_img2@2x.jpg'} />
                        </div>
                        <p className=" text-sm mt-8 leading-6 zen">
                        ジャケットやシャツなど、ドレッシーなアイテムを使ったワンランク上のカジュアルコーデや、ラギッドなアイテムを混ぜ合わせたボーダレスなコーデに加えてほしいブランドです。
                        </p>

                    </div>

                </div>
            </section>
            <section className="bg-white pt-14 pb-40">
                <div className="max-w-[740px] mx-auto px-4 lg:px-0">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-32">
                        <div className="flex flex-col">
                            <img src={imgDir() + '/laszlo@2x.png'}  className="relative block mx-auto h-[300px] mt-8 mb-4"/>
                            <h3 className="text-center font-bold tracking-wider text-2xl">Laszlo Budapest</h3>
                            <p className=" text-sm zen mt-6 leading-6 grow">
                                ヨーロッパ有数の靴生産地であるハンガリーで100年以上の歴史を持つ、隠れた名門。ハンドメイドの靴作りの技術を、職人たちが今も守り続けています。
                            </p>
                            <div className="flex justify-center mt-8">
                                <button onClick={()=>linkTo("/d-te/laszlo-budapest")} className="relative block w-40 py-2 text-sm tracking-wider font-bold group bg-white text-black">
                                    <div className="absolute inset-0 border border-black ">
                                    </div>
                                    MORE
                                    <div className="absolute inset-0 -translate-x-1 -translate-y-1 flex justify-center items-center bg-white border border-black pointer-events-none transition-transform group-hover:translate-x-0 group-hover:translate-y-0">
                                    MORE
                                    </div>
                                </button>
                            </div>

                        </div>
                        <div className="flex flex-col">
                            <img src={imgDir() + '/vass@2x.png'}  className="relative block mx-auto h-[300px] mt-8 mb-4"/>
                            <h3 className="text-center font-bold tracking-wider text-2xl">VASS SHOES</h3>
                            <p className=" text-sm zen mt-6 leading-6 grow">
                            伝説的靴職人のラズロ・ヴァーシュ氏が1978年にハンガリーで立ち上げたブランド。トラディショナルでありながらも圧倒的な存在感を放ちます。
                            </p>
                            <div className="flex justify-center mt-8">
                                <button onClick={()=>linkTo("/d-te/vass-shoes")} className="relative block w-40 py-2 text-sm tracking-wider font-bold group bg-white text-black">
                                    <div className="absolute inset-0 border border-black ">
                                    </div>
                                    MORE
                                    <div className="absolute inset-0 -translate-x-1 -translate-y-1 flex justify-center items-center bg-white border border-black pointer-events-none transition-transform group-hover:translate-x-0 group-hover:translate-y-0">
                                    MORE
                                    </div>
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}